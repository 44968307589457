<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">커뮤니티 수정</h2>
				<a @click="commnunityDetail()" class="btnBack">이전 페이지 이동</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- inputTable -->
				<div class="inputTable v2">
					<table>
						<caption>
							카테고리, 제목, 내용, 이미지 항목으로 구성된 커뮤니티 수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr class="fN">
								<th scope="row">카테고리</th>
								<td>
									<input
										type="text"
										title="카테고리"
										placeholder="카테고리를 입력해주세요."
										:value="categoryName"
										readonly
									/>
								</td>
							</tr>
							<tr class="fN">
								<th scope="row">제목 <span>*</span></th>
								<td>
									<input
										type="text"
										title="제목"
										placeholder="제목을 입력해주세요."
										required="required"
										v-model="title"
										maxlength="254"
									/>
								</td>
							</tr>
							<tr class="fN">
								<th scope="row">내용 <span>*</span></th>
								<td>
									<textarea
										title="내용"
										required="required"
										placeholder="내용을 입력해주세요."
										v-model="content"
										maxlength="10000"
										style="min-height: 150px;"
									></textarea>
								</td>
							</tr>
							<tr>
								<th scope="row" class="mNone">이미지</th>
								<td>
									<!-- file -->
									<div class="file">
										<input
											type="file"
											title="이미지 업로드"
											required="required"
											id="image"
											@change="onImageUpload"
											accept="image/*"
											multiple
										/>
										<span>이미지 업로드</span>
									</div>
									<span class="checkbox" v-if="checkedVal()">
										<input type="checkbox" id="chk" v-model="checkedValues" />
										<label for="chk01">원본으로 올리기</label>
									</span>
									<!--// file -->
									<!-- imgFileList -->
									<ul class="imgFileList v2">
										<li v-for="(image, index) in images" :key="index">
											<div class="imgArea">
												<img :src="imagesPreview[index]" alt="" />
											</div>
											<a @click="onImageDelete(index, image.fileId)" class="btnDel">삭제</a>
										</li>
									</ul>
									<!--// imgFileList -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
				<!-- inputTable -->
				<div class="inputTable">
					<table>
						<caption>
							첨부파일 항목으로 구성된 커뮤니티 수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row" class="mNone">첨부파일</th>
								<td>
									<!-- attaArea -->
									<div class="attaArea">
										<p v-for="(file, index) in files" :key="index">
											<a>{{ filesPreview[index] }}</a
											><a @click="onFileDelete(index, file.fileId)" class="btnDel">삭제</a>
										</p>
									</div>
									<!--// attaArea -->
									<!-- file -->
									<div class="file">
										<input
											type="file"
											title="첨부파일 업로드"
											required="required"
											id="file"
											@change="onFileUpload"
											multiple
										/>
										<span>첨부파일 업로드</span>
									</div>
									<!--// file -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- inner -->
		<div class="inner">
			<!-- btnBottom -->
			<div class="btnBottom">
				<button type="button" class="btn" @click="onUpdate()" :disabled="!saveBtn">저장하기</button>
			</div>
			<!--// btnBottom -->
		</div>
		<!--// inner -->
	</div>
	<!--// container -->
</template>
<script>
import post from '@/api/post';
// eslint-disable-next-line no-unused-vars
import file from '@/api/file';
import postFile from '@/api/postFile';
import { showSpinner, hideSpinner, isImage, getAuth, hideMenu, overFileSize } from '@/utils/cmm.js';

export default {
	mounted() {
		if (!this.id) {
			this.communityList();
			return false;
		}
		hideMenu();
		this.onGet();
	},
	data() {
		return {
			title: '',
			content: '',
			images: [],
			imagesPreview: [],
			files: [],
			filesPreview: [],
			id: this.$route.params.postId,
			category: '',
			categoryName: '',
			deleteImages: [],
			deleteFiles: [],
			searchKeyword: this.$route.params.searchKeyword,
			orderBy: this.$route.params.orderBy,
			checkedValues: false,
		};
	},
	computed: {
		saveBtn: {
			get() {
				return this.title.length > 0 && this.content.length > 0;
			},
			set() {},
		},
	},
	methods: {
		communityList() {
			this.$router.push({
				name: 'communityList',
				params: { category: this.category, searchKeyword: this.searchKeyword, orderBy: this.orderBy },
			});
		},
		commnunityDetail() {
			this.$router.push({
				name: 'communityDetail',
				params: { id: this.id, category: this.category, searchKeyword: this.searchKeyword, orderBy: this.orderBy },
			});
		},
		async onGet() {
			try {
				showSpinner();
				let res = await post.getOne(this.id);
				this.category = res.category;
				this.categoryName = res.categoryName;
				this.title = res.title;
				this.content = res.content;
				this.creatorName = res.creatorName;
				this.creatorImageId = res.creatorImageId;
				this.views = res.views;
				this.createDate = res.createDate;
				this.mine = getAuth().id === res.creator;

				let params = {
					postId: this.id,
					type: 'I',
				};
				let imageRes = await postFile.get(params);
				if (imageRes.total > 0) {
					this.images = imageRes.data;
					// 게시판에 저장된 이미지를 불러와서 먼저 보여준다.
					this.images.forEach(i => this.imagesPreview.push(`/file/${i.fileId}`));
				}
				params.type = 'F';
				let fileRes = await postFile.get(params);
				if (fileRes.total > 0) {
					this.files = fileRes.data;
					// 게시판에 저장된 파일을 불러와서 먼저 보여준다.
					this.files.forEach(f => this.filesPreview.push(f.fileName));
				}
			} catch (ex) {
				this.$alert(ex);
			} finally {
				hideSpinner();
			}
		},
		async onImageUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('image').value = '';
			for (let i in files) {
				if (!isImage(files[i])) {
					return this.$alert(`이미지는 png, jpg, jpeg, bmp의 확장자 파일만 올려주세요.`);
				}
			}
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`이미지 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.imagesPreview = [];
			this.images = this.images.concat(files);
			for (var index in this.images) {
				if (!this.images[index].fileId) {
					// 이미지 배열에 파일아이디가 없으면, 새로 추가한 이미지
					this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
				} else {
					// 이미지 배열에 파일아이디가 존재하면, 기존 이미지
					this.imagesPreview[index] = `/file/${this.images[index].fileId}`;
				}
			}
		},
		async onImageDelete(id, fileId) {
			this.images.splice(id, 1);
			this.imagesPreview = [];
			// 이미지 배열 삭제 요청시, 파일아이디가 존재하면 지울 이미지 배열에 추가한다.
			if (fileId) {
				this.deleteImages.push(fileId);
			}
			for (var index in this.images) {
				if (!this.images[index].fileId) {
					this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
				} else {
					this.imagesPreview[index] = `/file/${this.images[index].fileId}`;
				}
			}
		},
		async onFileUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('file').value = '';
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`첨부 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.filesPreview = [];
			this.files = this.files.concat(files);
			for (var index in this.files) {
				if (!this.files[index].fileId) {
					// 파일 배열에 파일아이디가 없으면, 새로 추가한 파일
					this.filesPreview[index] = this.files[index].name;
				} else {
					// 파일 배열에 파일아이디가 존재하면, 기존 파일
					this.filesPreview[index] = this.files[index].fileName;
				}
			}
		},
		async onFileDelete(id, fileId) {
			this.files.splice(id, 1);
			this.filesPreview = [];
			// 파일 배열 삭제 요청시, 파일아이디가 존재하면 지울 파일 배열에 추가한다.
			this.deleteFiles.push(fileId);
			for (var index in this.files) {
				if (!this.files[index].fileId) {
					this.filesPreview[index] = this.files[index].name;
				} else {
					this.filesPreview[index] = this.files[index].fileName;
				}
			}
		},
		async onUpdate() {
			let params = {
				category: this.category,
				title: this.title.trim(),
				content: this.content,
				id: this.id,
			};
			try {
				showSpinner();
				// 1. 지울 이미지나 파일이 존재하면 삭제 요청
				if (this.deleteImages.length > 0) {
					this.deleteImages.forEach(async di => await postFile.remove(di));
				}
				if (this.deleteFiles.length > 0) {
					this.deleteFiles.forEach(async df => await postFile.remove(df));
				}
				// 2. 새롭게 추가할 이미지나 파일이 존재하면 새로 추가 요청
				let newImages = this.images.filter(i => !i.fileId);
				if (newImages.length > 0) {
					let imageRes = await file.upload(newImages, this.checkedValues);
					params.imageInfos = imageRes.data;
				}
				let newFiles = this.files.filter(f => !f.fileId);
				if (newFiles.length > 0) {
					let fileRes = await file.upload(newFiles, true);
					params.fileInfos = fileRes.data;
				}
				// 3. 게시판 등록
				await post.update(params);
				this.commnunityDetail();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		checkedVal() {
			// this.checkedValues = false;
			return !(Array.isArray(this.images) && this.images == 0);
		},
	},
};
</script>
